import React, { FC } from 'react';
import {
  ButtonContainer,
  ButtonLink,
  ExternalLink,
  Label,
  NewModal,
  Paragraph,
  SectionContainer,
  TextBlock,
  useModalContext,
} from '@yieldstreet/ui-kit';

import fundPerformanceDesktop from 'images/home/fund-performance-desktop.png';

import { modalId } from './FundPerformance.model';
import {
  FundPerformanceSection,
  BannerWrapper,
  PerformanceBanner,
  ContentWrapper,
  LegalTextWrapper,
  ModalWrapper,
} from './FundPerformance.style';

export const FundPerformance: FC<{ commentaryLink: string }> = ({ commentaryLink }) => {
  const { showModal } = useModalContext();

  return (
    <FundPerformanceSection>
      <SectionContainer>
        <TextBlock
          heading="Fund performance"
          align="center"
          headingTagType={2}
          headingType={2}
          content={
            <>
              <ContentWrapper>
                <Paragraph>As of June 2024</Paragraph>
              </ContentWrapper>
            </>
          }
        />

        <BannerWrapper>
          <PerformanceBanner src={fundPerformanceDesktop} />
        </BannerWrapper>

        <LegalTextWrapper>
          <Label>
            Source: Yieldstreet and Bloomberg as{' '}
            <ExternalLink onClick={() => showModal(modalId)}>of 6/30/2024</ExternalLink>
          </Label>
        </LegalTextWrapper>
        <ButtonContainer alignment="center">
          <ButtonLink
            buttonProps={{ buttonType: 'secondary' }}
            linkProps={{ href: commentaryLink, target: '_blank' }}
          >
            View the latest Fund commentary
          </ButtonLink>
        </ButtonContainer>
      </SectionContainer>
      <NewModal modalId={modalId}>
        <ModalWrapper>
          <TextBlock
            heading="Historical performance chart; use of benchmarks"
            headingTagType={3}
            headingType={3}
            content={
              <>
                <Paragraph>
                  Performance returns quoted herein represent past performance, which is not a
                  guarantee of future results. Returns for periods longer than one year are
                  presented as annualized returns. All investing involves risk, including the loss
                  of principal. Investment returns and principal value will fluctuate, so you may
                  have a gain or loss when shares are sold. The financial index returns referenced
                  above are provided for informational purposes only. The holdings and portfolio
                  characteristics of Yieldstreet Alternative Income Fund differ materially from
                  those of the index. The returns for the Yieldstreet Alternative Income Fund
                  represent the returns from the Fund's net asset value per share, assuming the
                  reinvestment of all distributions. The Bloomberg US Agg Total Return Value
                  Unhedged USD Index is a broad-based benchmark that measures the investment grade,
                  U.S. dollar-denominated, fixed-rate taxable bond market. All indexes are an
                  independent, third-party indexes. Factors affecting portfolio performance that do
                  not affect index performance may include, but are not limited to, portfolio
                  rebalancing, the timing of cash flows, differences in asset classes invested in
                  which the Fund invests and the index represents, and investment duration. In
                  addition, financial indices do not reflect the impact of fees, applicable taxes or
                  trading costs which reduce returns. Unless otherwise noted, financial indices
                  assume reinvestment of dividends and interest. All indices are unmanaged. You
                  cannot make a direct investment in an index. The statistical data regarding such
                  indices has not been independently verified by Yieldstreet.{' '}
                </Paragraph>
                <Paragraph>
                  The Yieldstreet Alternative Income Fund's inception date is March 9, 2020.
                </Paragraph>
              </>
            }
          />
        </ModalWrapper>
      </NewModal>
    </FundPerformanceSection>
  );
};
