import React, { FunctionComponent } from 'react';
import { Tooltip } from '@mui/material';

import { BadgeType } from '@yieldstreet/platform-kit';

import { Icon } from '../icon';

import { BadgeConfiguration, BadgeProps, useTestIds } from './BadgeV2.model';
import { BadgeContainer, IconContainer, SParagraph } from './BadgeV2.style';

/**
 * @deprecated This component will eventually be migrated as part of Tag
 */
export const BadgeV2: FunctionComponent<BadgeProps> = props => {
  const { iconColor, icon, text, type } = props;

  const testIds = useTestIds(props);

  if (!text) return null;

  const badgeStyle = BadgeConfiguration[type || BadgeType.DEFAULT];
  return (
    <Tooltip title={text} placement="top">
      <BadgeContainer backgroundColor={badgeStyle.backgroundColor} {...testIds.badge.attr}>
        {icon && (
          <IconContainer {...testIds.icon.attr}>
            <Icon iconType={icon} color={iconColor} />
          </IconContainer>
        )}
        <>
          <SParagraph textColor={badgeStyle.textColor}>{text}</SParagraph>
        </>
      </BadgeContainer>
    </Tooltip>
  );
};
