import React from 'react';

import { Heading, Paragraph } from '../../content';
import { Block, ImageContent, TextContent } from './ProductType.style';
import { ProductTypeProps, useTestIds } from './ProductType.model';

export const ProductType = ({ image, title, content, testId, onClick }: ProductTypeProps) => {
  const testIds = useTestIds({ testId: testId });

  return (
    <Block onClick={onClick} {...testIds.base.attr}>
      <ImageContent bgImg={image} {...testIds.image.attr} />
      <TextContent>
        <Heading type={5} {...testIds.title.attr}>
          {title}
        </Heading>
        <Paragraph {...testIds.content.attr}>{content}</Paragraph>
      </TextContent>
    </Block>
  );
};
