import React from 'react';

import { LayoutGroup } from 'framer-motion';

import arrow from '../../assets/graphics/icons/arrow.svg';
import { mapToTestId, DropDownProps } from './DropDown.model';
import {
  Field,
  FieldMain,
  FieldLabel,
  FieldValue,
  FieldLayoutGroup,
  FieldIcon,
  fieldValueAnimation,
  fieldIconAnimation,
} from './DropDown.style';

export const DropDown: React.FC<DropDownProps> = ({
  value,
  active,
  withBorder,
  label,
  inline,
  fullWidth,
  maxWidth,
  responsive,
  testId,
  type,
  ...rest
}) => {
  const testIds = mapToTestId(testId);

  return (
    <Field
      type={type}
      active={active}
      withBorder={withBorder}
      fullWidth={fullWidth}
      responsive={responsive}
      {...testIds.base.attr}
      {...rest}
    >
      <FieldMain fullWidth={fullWidth} maxWidth={maxWidth}>
        <LayoutGroup>
          <FieldLayoutGroup inline={inline}>
            <FieldLabel {...testIds.label.attr}>
              {label}
              {value ? ': ' : ''}
            </FieldLabel>
            {value && (
              <FieldValue
                {...fieldValueAnimation(inline)}
                {...testIds.value.attr}
              >{` ${value}`}</FieldValue>
            )}
          </FieldLayoutGroup>
        </LayoutGroup>
      </FieldMain>
      <FieldIcon src={arrow} {...fieldIconAnimation(active)} {...testIds.icon.attr} />
    </Field>
  );
};
