import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';

import { Button, ButtonProps } from '../button';

import { ThemedButtonProps, ButtonThemes, testIds } from './ThemedButton.model';

export const ThemedButton: FC<ThemedButtonProps & ButtonProps> = ({ children, theme, ...rest }) => {
  return (
    <ThemeProvider theme={ButtonThemes[theme]}>
      <Button {...testIds.base.attr} {...rest}>
        {children}
      </Button>
    </ThemeProvider>
  );
};
