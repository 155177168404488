import React, { FC } from 'react';
import { BaseProps, ButtonProps, Heading } from '@yieldstreet/ui-kit';

import { Wrapper, IconWrapper, ContentWrapper, ButtonWrapper } from './ProcessInfo.styles';
import { useTestIds } from './ProcessInfo.model';

type Props = {
  icon: React.ReactNode;
  iconCentered?: boolean;
  title: string;
  buttons?: React.ReactElement<ButtonProps> | React.ReactElement<ButtonProps>[];
} & BaseProps;

export const ProcessInfo: FC<Props> = ({
  icon,
  iconCentered,
  title,
  children,
  buttons,
  ...props
}) => {
  const testIds = useTestIds(props);

  return (
    <Wrapper {...testIds.base.attr}>
      {icon && (
        <IconWrapper centered={iconCentered} {...testIds.icon.attr}>
          {icon}
        </IconWrapper>
      )}
      <Heading type={4} {...testIds.title.attr} marginBottom="m">
        {title}
      </Heading>
      <ContentWrapper>{children}</ContentWrapper>
      {buttons && <ButtonWrapper>{buttons}</ButtonWrapper>}
    </Wrapper>
  );
};
