import React from 'react';
import {
  TextBlock,
  DesktopOnly,
  MobileOnly,
  SectionContainer,
  Paragraph,
} from '@yieldstreet/ui-kit';

import assetClassesBanner from 'images/home/table-holdings-desktop.png';
import assetClassesBannerMobile from 'images/home/table-holdings-mobile.png';
import { AssetClassesImage, AssetClassesWrapper } from './AssetClasses.style';

export const Holdings = () => (
  <AssetClassesWrapper>
    <SectionContainer>
      <TextBlock
        heading="A selection of holdings"
        align="center"
        mobileAlign="start"
        content={
          <>
            <Paragraph>
              The following are the top 10 holdings in the Fund, as of March 31, 2024.
            </Paragraph>
            <DesktopOnly>
              <AssetClassesImage src={assetClassesBanner} />
            </DesktopOnly>
            <MobileOnly>
              <AssetClassesImage src={assetClassesBannerMobile} />
            </MobileOnly>
          </>
        }
      />
    </SectionContainer>
  </AssetClassesWrapper>
);
