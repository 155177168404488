import React from 'react';

import { IconProps, Icons } from './Icon.model';
import { IconWrapper } from './Icon.style';

export const Icon: React.FC<IconProps> = props => {
  const { iconType } = props;

  const icon = Icons[iconType];

  return <IconWrapper {...props} icon={icon} />;
};
