import React, { useEffect } from 'react';
import { StepProgressBarContainerProps } from './StepProgressBar.model';
import {
  ItemContainer,
  ItemTitle,
  ProgressBar,
  ProgressDot,
  ProgressLine,
  StepProgressBarContainer,
} from './StepProgressBar.style';

export const StepProgressBar = ({
  items,
  step = 1,
  scrollRef,
  ...rest
}: StepProgressBarContainerProps) => {
  const totalSteps = items?.length || 0;
  const progress = ((step - 1) / (totalSteps - 1)) * 100;
  const getTextPosition = (index: number) => (index / (totalSteps - 1)) * 100;
  const progressBaseId = 'progress-';

  useEffect(() => {
    const scrollElement = scrollRef?.current;

    if (scrollElement && step > 1) {
      for (let i = 1; i < step; i++) {
        const element = document.getElementById(`${progressBaseId}${i}`);

        if (element) {
          scrollElement.scrollLeft += element.offsetWidth;
        }
      }
    }
  }, [step, progressBaseId, scrollRef]);

  if (!totalSteps) {
    return null;
  }

  return (
    <StepProgressBarContainer {...rest}>
      <ProgressDot left={progress} />
      <ProgressLine>
        <ProgressBar width={progress} />
        {items?.map((item, index) => (
          <ItemContainer key={index} left={getTextPosition(index)}>
            <ItemTitle id={`${progressBaseId}${index + 1}`} semiBold={step === index + 1}>
              {item}
            </ItemTitle>
          </ItemContainer>
        ))}
      </ProgressLine>
    </StepProgressBarContainer>
  );
};
