import React, { useMemo } from 'react';
import { camelCase } from 'lodash';

import { BaseProps } from '../../types/components/base';

import { ProgressTableRow, RowData } from './components/progress-table-row';
import { ProgressTableProps, useTestIds, getTestIds } from './ProgressTable.model';
import { Wrapper, HeaderRow, LeftHeaderElement, RightHeaderElement } from './ProgressTable.style';

export const ProgressTable = ({
  leftHeading,
  rightHeading,
  rowData,
  ...props
}: ProgressTableProps & BaseProps) => {
  const testIds = useTestIds(props);

  const renderRows = useMemo(() => {
    return rowData.map((row: RowData, idx: number) => {
      const lastRow = idx === rowData.length - 1;

      return (
        <ProgressTableRow
          {...row}
          lastRow={lastRow}
          key={idx}
          testId={`${testIds.row.id}-${camelCase(row.title)}`}
        />
      );
    });
  }, [rowData, testIds.row.id]);

  return (
    <Wrapper {...testIds.base.attr}>
      <HeaderRow {...testIds.header.attr}>
        <LeftHeaderElement {...testIds.headerLeft.attr}>{leftHeading}</LeftHeaderElement>
        <RightHeaderElement {...testIds.headerRight.attr}>{rightHeading}</RightHeaderElement>
      </HeaderRow>
      {renderRows}
    </Wrapper>
  );
};

ProgressTable.getTestIds = getTestIds;
