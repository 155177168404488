import styled from 'styled-components';
import { MarketingContainer, media } from '@yieldstreet/ui-kit';

export const Background = styled.section`
  background: radial-gradient(132.82% 132.83% at 50% 105.01%, #a7c1d9 0%, #556c9a 100%);
  background-position: center;
  text-align: center;

  & p {
    color: ${({ theme }) => theme.colors.text_paragraph_inverse} !important;
  }

  ${media.large`
    padding: 0 ${({ theme }) => theme.spacing['2xl']};
  `}
`;

export const StatsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.ml};
  margin: ${({ theme }) => theme.spacing.xl} 0;

  ${media.desktop`
    flex-direction: row;
  `}
`;

export const StatItem = styled.div<{ hasModal: boolean }>`
  text-align: center;
  padding: 0 ${({ theme }) => theme.spacing.xl};
  cursor: ${({ hasModal }) => hasModal && 'pointer'};

  & > p:first-of-type {
    text-decoration: ${({ hasModal }) => hasModal && 'underline dotted'};
  }

  ${media.large`
    border-left: 1px solid ${({ theme }) => theme.colors.border_inverse};
    &:first-of-type {
      border-left: 0;
    }
  `}
`;

export const ModalWrapper = styled(MarketingContainer)`
  background-color: ${({ theme }) => theme.colors.background_page};
  box-shadow: ${({ theme }) => theme.shadows.elevation_1};
  padding: ${({ theme }) => theme.spacing.l};
  max-width: 700px;
`;

export const ModalList = styled.ul`
  padding-left: ${({ theme }) => theme.spacing.ml};

  & > li {
    list-style-type: disc;
    margin-bottom: -${({ theme }) => theme.spacing.sm};
  }
`;
