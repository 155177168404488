import React, { useMemo } from 'react';
import { Tooltip } from '@mui/material';

import { tooltipStyles } from './TooltipV2.style';
import { TooltipV2Props, testIds } from './TooltipV2.model';

export const TooltipV2: React.FC<TooltipV2Props> = props => {
  const { children, title, arrow, placement = 'bottom', inverse, ...extraProps } = props;

  const tooltipStyle = useMemo(() => tooltipStyles(inverse), [inverse]);

  return (
    <Tooltip
      {...testIds.base.attr}
      title={title}
      classes={{ tooltip: tooltipStyle().tooltip, arrow: tooltipStyle().arrow }}
      arrow={arrow}
      placement={placement}
      {...extraProps}
    >
      <span>{children}</span>
    </Tooltip>
  );
};
