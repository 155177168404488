import React from 'react';
import { Bar, BarWrapper, Count, Wrapper } from './StepCounter.style';

export type StepCounterProps = {
  currentStep: number;
  maxStep: number;
};

export const StepCounter = ({ currentStep, maxStep }: StepCounterProps) => {
  return (
    <Wrapper>
      <Count>
        {currentStep} / {maxStep}
      </Count>
      <BarWrapper>
        {[...Array(maxStep)].map((_e, index) => {
          return <Bar key={index} $complete={index + 1 === currentStep} />;
        })}
      </BarWrapper>
    </Wrapper>
  );
};
