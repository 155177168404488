import React, { FC } from 'react';
import { BaseProps } from '@yieldstreet/ui-kit';

import { IconImg } from './StatusIcon.styles';
import { useTestIds, statusIcons, ProcessStatus } from './StatusIcon.model';

export type StatusIconProps = {
  status: ProcessStatus;
} & BaseProps;

export const StatusIcon: FC<StatusIconProps> = ({ status, ...props }) => {
  const testIds = useTestIds(props);

  return <IconImg src={statusIcons[status]} {...testIds.base.attr} />;
};
