import React, { useMemo } from 'react';

import { Label, Paragraph } from '../../content';
import { Tooltip } from '../../elements';
import { BaseProps } from '../../types';
import { OfferingTitle } from '../offering-title';

import {
  TopBar,
  Wrapper,
  Content,
  Secondary,
  Subtitle,
  SecondaryItem,
  SecondaryItemTitle,
  SecondaryItemContent,
  DividerItem,
  SecondaryItemTitleTooltipWrapper,
  SecondaryViewPort,
} from './OfferingBanner.style';
import { OfferingBannerProps, useTestIds } from './OfferingBanner.model';

export const OfferingBanner: React.FC<OfferingBannerProps & BaseProps> = ({
  topBar,
  title,
  subtitle,
  secondary,
  icon,
  ...props
}) => {
  const testIds = useTestIds(props);

  const secondaryItems = useMemo(() => {
    return secondary?.map(({ title, content, tooltip }, idx) => {
      return (
        <React.Fragment key={`${testIds.contentSecondaryItem.id}-${idx}`}>
          <SecondaryItem {...testIds.contentSecondaryItem.attr}>
            <SecondaryItemTitle>
              <Label small inverse>
                {title}
              </Label>
              {tooltip && (
                <SecondaryItemTitleTooltipWrapper>
                  <Tooltip testId={testIds.contentToolTip.id}>{tooltip}</Tooltip>
                </SecondaryItemTitleTooltipWrapper>
              )}
            </SecondaryItemTitle>
            <SecondaryItemContent inverse>{content}</SecondaryItemContent>
          </SecondaryItem>
          <DividerItem />
        </React.Fragment>
      );
    });
  }, [secondary, testIds]);

  return (
    <Wrapper {...testIds.base.attr}>
      <TopBar {...testIds.topBar.attr}>
        <Paragraph size="chart">{topBar}</Paragraph>
      </TopBar>
      <Content {...testIds.content.attr}>
        <OfferingTitle
          icon={icon}
          subtitle={
            <Subtitle inverse {...testIds.contentSubtitle.attr}>
              {subtitle}
            </Subtitle>
          }
          title={title}
          inverse
          testId={testIds.contentMain.id}
        />
        <Secondary {...testIds.contentSecondary.attr}>
          <SecondaryViewPort>{secondaryItems}</SecondaryViewPort>
        </Secondary>
      </Content>
    </Wrapper>
  );
};
