import React from 'react';

import SEO from 'components/seo';
import BaseLayout from 'components/layouts/BaseLayout';
import { SimpleHeader } from 'components/ui/Header';
import { Hero } from 'page-specifics/home/Hero';
import { AboutFund } from 'page-specifics/home/AboutFund';
import { FundPerformance } from 'page-specifics/home/FundPerformance';
import { AssetClasses } from 'page-specifics/home/AssetClasses';
import { Holdings } from 'page-specifics/home/Holdings';
import { FundLeadership } from 'page-specifics/home/FundLeadership';
import { Partners } from 'page-specifics/home/Partners';
import { Stats } from 'page-specifics/home/Stats';
import { Resources } from 'page-specifics/home/Resources';
import { FundFooter } from 'page-specifics/home/FundFooter';
import { homePageDocs } from 'page-specifics/home/homeData';

export const HomePage = () => (
  <BaseLayout>
    <SEO title="Yieldstreet Alternative Income Fund" />
    <SimpleHeader />
    <Hero />
    <AboutFund />
    <FundPerformance commentaryLink={homePageDocs.commentaryLink} />
    <AssetClasses />
    <Holdings />
    <FundLeadership />
    <Partners />
    <Stats />
    <Resources />
    <FundFooter link={homePageDocs.prospectusLink} />
  </BaseLayout>
);

export default HomePage;
