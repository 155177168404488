import React, { useEffect, useRef, useCallback } from 'react';

import { AnimatePresence } from 'framer-motion';

import { ContextMenuProps, mapToTestId } from './ContextMenu.model';
import {
  ContextAction,
  ContextContainer,
  ContextWindow,
  ContextWindowAnimation,
} from './ContextMenu.style';

export const ContextMenu: React.FC<ContextMenuProps> = ({
  open,
  onChange,
  children,
  renderTrigger,
  onClose = () => {},
  onOpen = () => {},
  align,
  testId,
  maxHeight,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const testIds = mapToTestId(testId);

  const onDocumentClick = useCallback(
    e => {
      if (open && ref?.current && !ref.current.contains(e.target)) {
        onChange(false);
      }
    },
    [open, onChange]
  );

  useEffect(() => {
    if (open) {
      onOpen();
    } else {
      onClose();
    }
  }, [open, onOpen, onClose]);

  useEffect(() => {
    window.document.addEventListener('mousedown', onDocumentClick);
    return () => window.document.removeEventListener('mousedown', onDocumentClick);
  }, [onDocumentClick]);

  const toggle = () => {
    onChange(!open);
  };

  return (
    <ContextContainer ref={ref} {...testIds.base.attr}>
      <ContextAction onClick={toggle} {...testIds.action.attr}>
        {renderTrigger}
      </ContextAction>
      <AnimatePresence initial={false}>
        {open && (
          <ContextWindow
            align={align}
            maxHeight={maxHeight}
            {...testIds.window.attr}
            {...ContextWindowAnimation}
          >
            {children}
          </ContextWindow>
        )}
      </AnimatePresence>
    </ContextContainer>
  );
};
