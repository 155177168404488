import { ReactNode } from 'react';

interface HeroStatsProps {
  label: ReactNode;
  value: string;
  modalId: string;
  footNote?: string;
}

export const heroStats: HeroStatsProps[] = [
  {
    label: 'Net annualized yield',
    value: `8.3%`,
    modalId: 'net-annualized-yield-modal',
  },
  {
    label: 'Assets under management',
    value: `$152M+`,
    modalId: '',
    footNote: 'as of 06/30/2024',
  },
];
