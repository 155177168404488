import React from 'react';

import { useTestIds, StatusTagProps, StatusTagInfo } from './StatusTag.model';
import { Tag, TagContent, TagLabel, Icon } from './StatusTag.style';

/**
 * @deprecated This component must be deleted and replaced by ActionCell (MUI Chip)
 */
export const StatusTag = (props: StatusTagProps) => {
  const { status, label, small, showIcon } = props;

  const testIds = useTestIds(props);

  return (
    <Tag status={status} {...testIds.base.attr}>
      <TagContent>
        <TagLabel small={small} {...testIds.label.attr}>
          {label}
        </TagLabel>
        {showIcon ? (
          <Icon src={StatusTagInfo(status).icon} alt="Status tag icon" {...testIds.icon.attr} />
        ) : null}
      </TagContent>
    </Tag>
  );
};
