import React from 'react';
import {
  ExternalProviderVisualProps,
  externalProviderTestIds,
} from './ExternalProviderVisual.model';
import { Arrow, ArrowWrapper, ExternalProviderWrapper } from './ExternalProviderVisual.style';
import { ExternalProviderIcon } from './ExternalProviderIcon';

export const ExternalProviderVisual = ({
  fromIcon,
  toIcon,
  showArrow,
}: ExternalProviderVisualProps) => {
  return (
    <ExternalProviderWrapper {...externalProviderTestIds.base.attr}>
      {fromIcon && (
        <ExternalProviderIcon
          src={fromIcon.icon}
          alt={fromIcon?.alt || ''}
          backgroundColor={fromIcon.backgroundColor}
          {...externalProviderTestIds.fromIcon.attr}
        />
      )}
      {showArrow && (
        <ArrowWrapper {...externalProviderTestIds.arrows.attr}>
          <Arrow />
          <Arrow />
          <Arrow />
          <Arrow />
        </ArrowWrapper>
      )}
      {toIcon && (
        <ExternalProviderIcon
          src={toIcon.icon}
          alt={toIcon?.alt || ''}
          backgroundColor={toIcon.backgroundColor}
          {...externalProviderTestIds.toIcon.attr}
        />
      )}
    </ExternalProviderWrapper>
  );
};
