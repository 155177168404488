import React, { ReactNode, useRef } from 'react';
import { isEmpty } from 'lodash';

import { StepProgressBar } from '../../elements/step-progress-bar';
import Logo from '../../assets/logos/logo-ys-2022.png';
import { Button, Heading, Label, Paragraph } from '../../content';
import {
  BarLabelWrapper,
  BarWrapper,
  ButtonWrapper,
  ContentWrapepr,
  HeadingContainer,
  LogoContainer,
  LogoImg,
  PageStepContainer,
  ScrollContent,
} from './PageStep.style';
import {
  PageStepContentProps,
  PageStepProps,
  PageStepScrollProps,
  pageStepTestIds,
} from './PageStep.model';

export const PageStepContent = ({ title, description, cta }: PageStepContentProps) => {
  return (
    <ContentWrapepr>
      <HeadingContainer>
        <Heading type={2} {...pageStepTestIds.title.attr}>
          {title}
        </Heading>
      </HeadingContainer>
      <Paragraph {...pageStepTestIds.description.attr}>{description}</Paragraph>

      {!isEmpty(cta) && (
        <ButtonWrapper>
          <Button
            {...pageStepTestIds.cta.attr}
            fullWidth
            buttonType={cta?.type ?? 'primary'}
            onClick={cta?.callback}
          >
            {cta?.text}
          </Button>
        </ButtonWrapper>
      )}
    </ContentWrapepr>
  );
};

export const PageStepScroll = ({ step }: PageStepScrollProps) => {
  const scrollRef = useRef<HTMLDivElement>();
  const hasStepItems = !isEmpty(step?.items);
  const hasStepText = !isEmpty(step?.text);

  return (
    <>
      <ScrollContent ref={scrollRef as React.RefObject<HTMLDivElement>}>
        <BarWrapper>
          {hasStepItems && (
            <StepProgressBar
              {...pageStepTestIds.step.attr}
              step={step?.current}
              items={step?.items}
              scrollRef={scrollRef}
            />
          )}
        </BarWrapper>
      </ScrollContent>
      {hasStepText && (
        <BarLabelWrapper>
          <Label {...pageStepTestIds.stepText.attr}>{step?.text}</Label>
        </BarLabelWrapper>
      )}
    </>
  );
};

export const PageStepContentTemplate = ({ title, description, cta, step }: PageStepProps) => {
  return (
    <>
      <PageStepContent title={title} description={description} cta={cta} />
      <PageStepScroll step={step} />
    </>
  );
};

export const PageStep = ({ children }: { children: ReactNode }) => {
  return (
    <PageStepContainer {...pageStepTestIds.container.attr}>
      <LogoContainer {...pageStepTestIds.logo.attr}>
        <a href="/">
          <LogoImg src={Logo} />
        </a>
      </LogoContainer>
      {children}
    </PageStepContainer>
  );
};
