import React, { useMemo } from 'react';
import { camelCase } from 'lodash';

import { BaseProps } from '../../types/components/base';

import { ProgressBarCheckbox } from './components/progress-bar-checkbox';
import { Wrapper } from './ProgressBar.style';
import { useTestIds, ProgressBarProps, getTestIds } from './ProgressBar.model';

export const ProgressBar = ({ checkboxArr, ...props }: ProgressBarProps & BaseProps) => {
  const testIds = useTestIds(props);

  const renderCheckboxes = useMemo(
    () =>
      checkboxArr.map((checkbox, idx) => {
        const testId = `${testIds.base.id}-${camelCase(checkbox.copy)}`;

        return (
          <ProgressBarCheckbox
            key={`${testId}-${idx}`}
            testId={testId}
            lastElement={idx === checkboxArr.length - 1}
            numOfElements={checkboxArr.length}
            {...checkbox}
          />
        );
      }),
    [checkboxArr, testIds.base.id]
  );

  return <Wrapper {...testIds.base.attr}>{renderCheckboxes}</Wrapper>;
};

ProgressBar.getTestIds = getTestIds;
