import React from 'react';
import {
  ExternalLink,
  Paragraph,
  TextBlock,
  SectionContainer,
  NewModal,
  useModalContext,
} from '@yieldstreet/ui-kit';
import { Divider } from '@yieldstreet/ui-kit/legacy/navigation/footer/Footer.style.ts';

import { aboutFundData } from './AboutFund.model';
import { AboutItem, Icon, Background, Wrapper, ModalWrapper } from './AboutFund.style';

export const AboutFund = () => {
  const { showModal } = useModalContext();

  return (
    <Background>
      <SectionContainer>
        <TextBlock
          heading="Broad exposure, one fund"
          align="center"
          content={
            <Paragraph paddingHorizontal={{ desktop: '5xl' }}>
              The Fund seeks primarily asset-backed and cash flow-backed opportunities across asset
              classes that target regular income. As a secondary objective, the Fund seeks capital
              appreciation.
            </Paragraph>
          }
        />
        <Wrapper>
          {aboutFundData.map(({ img, title, description, modalId }) => (
            <AboutItem key={title}>
              <Icon src={img} width={44} />
              <TextBlock
                headingTagType={4}
                headingType={3}
                heading={title}
                content={<Paragraph>{description}</Paragraph>}
                button={
                  modalId && <ExternalLink onClick={() => showModal(modalId)}>More</ExternalLink>
                }
              />
            </AboutItem>
          ))}
        </Wrapper>
      </SectionContainer>

      <NewModal modalId="net-annualized-yield-modal">
        <ModalWrapper>
          <TextBlock
            heading="Net annualized yield"
            headingTagType={3}
            headingType={3}
            content={
              <>
                <Paragraph>
                  The current annualized yield is calculated by taking the four most recent
                  quarterly distributions and dividing it by prior quarter-end NAV.
                </Paragraph>
                <Paragraph>
                  Distributions are made to Fund investors quarterly, subject to the authorization
                  of the Fund’s Board of Directors.
                </Paragraph>
                <Paragraph>
                  Cash distributions are automatically reinvested through our Dividend Reinvestment
                  Program (DRIP); however, you may choose to opt-out of the DRIP at any time to
                  receive the distributions in cash.
                </Paragraph>
                <Divider />

                <Paragraph size="small">
                  The Fund's distribution may exceed its earnings. Therefore, a portion of the
                  Fund's distribution may be a return of the money you originally invested and
                  represent a return of capital to you for tax purposes.
                </Paragraph>
              </>
            }
          />
        </ModalWrapper>
      </NewModal>

      <NewModal modalId="liquidity-modal">
        <ModalWrapper>
          <TextBlock
            heading="Liquidity"
            headingTagType={3}
            headingType={3}
            content={
              <>
                <Paragraph>
                  The Fund expects to offer investors the opportunity to liquidate at least a
                  portion of their shares on a quarterly basis, subject to approval by the Fund's
                  Board of Directors and other limitations outlined in the Fund's Prospectus.
                </Paragraph>
                <Paragraph>
                  The Fund will limit the number of shares to be repurchased in any calendar year to
                  no more than 20% of shares outstanding in the prior calendar year, or no more than
                  5% in each quarter. If the number of shares submitted for repurchase by investors
                  exceeds the number of shares the Fund seeks to repurchase, the Fund will
                  repurchase shares from investors on a pro-rata basis, and not on a first-come,
                  first-served basis.
                </Paragraph>
              </>
            }
          />
        </ModalWrapper>
      </NewModal>
    </Background>
  );
};
