import React, { useMemo } from 'react';
import Step from '@material-ui/core/Step';

import { ProgressBarV2Status, ProgressBarV2Props, useTestIds } from './ProgressBarV2.model';
import {
  CustomConnector,
  CustomStepLabel,
  CustomStepper,
  CustomStepContent,
} from './ProgressBarV2.style';
import { checkNextStepActive, getStepIcon, isCompleteStatus } from './ProgressBarV2.utils';

import { Label, Paragraph } from '../../content';

export const ProgressBarV2 = ({
  orientation = 'horizontal',
  steps,
  testId = 'progress-bar-v2',
}: ProgressBarV2Props) => {
  const testIds = useTestIds({ testId });

  const isVertical = useMemo(() => orientation === 'vertical', [orientation]);

  const renderSteps = useMemo(
    () =>
      steps.map(({ label, description, status }, idx) => (
        <Step
          completed={status === ProgressBarV2Status.CLEARED}
          active={status === ProgressBarV2Status.ACTIVE}
          key={label}
          expanded={isVertical}
        >
          <CustomStepLabel StepIconComponent={props => getStepIcon({ ...props, status, testId })}>
            <Paragraph
              size="small"
              semiBold={isCompleteStatus(status)}
              marginBottom={!isVertical && 'xs'}
              {...testIds.label.attr}
            >
              {label}
            </Paragraph>
            {!isVertical && (
              <Label small {...testIds.description.attr}>
                {description}
              </Label>
            )}
          </CustomStepLabel>
          {isVertical && (
            <CustomStepContent
              completed={checkNextStepActive(steps, idx)}
              last={idx === steps.length - 1}
            >
              <Label small {...testIds.description.attr}>
                {description}
              </Label>
            </CustomStepContent>
          )}
        </Step>
      )),
    [isVertical, steps, testId, testIds]
  );

  return (
    <CustomStepper
      orientation={isVertical ? 'vertical' : 'horizontal'}
      alternativeLabel={!isVertical}
      connector={<CustomConnector />}
      {...testIds.container.attr}
    >
      {renderSteps}
    </CustomStepper>
  );
};
