import styled from 'styled-components';
import { media, MarketingContainer } from '@yieldstreet/ui-kit';

export const FundPerformanceSection = styled.section``;

export const BannerWrapper = styled.div`
  overflow: auto;
`;

export const PerformanceBanner = styled.img`
  width: 250%;
  overflow: scroll;
  margin: ${({ theme }) => theme.spacing.m} auto 0 auto;
  display: block;

  ${media.large`
    margin-top: ${({ theme }) => theme.spacing.l};
    max-width: 100%;
  `}
`;

export const ContentWrapper = styled.div`
  text-align: center;
  ${media.desktop`
    max-width: 650px;
  `}
`;

export const LegalTextWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.m};
  ${media.desktop`
    text-align: right;
  `}
`;

export const ModalWrapper = styled(MarketingContainer)`
  background-color: ${({ theme }) => theme.colors.background_page};
  box-shadow: ${({ theme }) => theme.shadows.elevation_1};
  padding: ${({ theme }) => theme.spacing.l};
  max-width: 700px;
`;
