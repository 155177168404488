import React from 'react';
import {
  TextBlock,
  MarketingCinematicContainer,
  DesktopOnly,
  MobileOnly,
} from '@yieldstreet/ui-kit';

import assetClassesBanner from 'images/home/asset-classes-banner-desktop.png';
import assetClassesBannerMobile from 'images/home/asset-classes-banner-mobile.png';
import { AssetClassesImage, AssetClassesWrapper } from './AssetClasses.style';

export const AssetClasses = () => (
  <MarketingCinematicContainer withBorder>
    <AssetClassesWrapper>
      <TextBlock
        heading="Income from a range of strategies"
        align="center"
        mobileAlign="start"
        content={
          <>
            <DesktopOnly>
              <AssetClassesImage src={assetClassesBanner} />
            </DesktopOnly>
            <MobileOnly>
              <AssetClassesImage src={assetClassesBannerMobile} />
            </MobileOnly>
          </>
        }
      />
    </AssetClassesWrapper>
  </MarketingCinematicContainer>
);
