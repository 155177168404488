import React from 'react';
import { ActionCellProps, ActionIcon, useTestIds } from './ActionCell.model';
import { ActionButton, ActionContent } from './ActionCell.style';

/**
 * @todo This component will be eventually migrated to MUI Chip
 */
export const ActionCell = (props: ActionCellProps) => {
  const { label, type, showIcon, onClick } = props;
  const testIds = useTestIds(props);

  return (
    <ActionButton {...testIds.component.attr} type={type} onClick={onClick}>
      <ActionContent {...testIds.base.attr}>
        {label}
        {showIcon ? ActionIcon(type) : null}
      </ActionContent>
    </ActionButton>
  );
};
